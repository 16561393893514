import styled, { css } from 'styled-components'
import { Select } from 'antd'

export const SelectGlobalStyles = css`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    padding: 5px 7px 5px 10px;
    height: unset;
  }
`

export const Wrapper = styled(Select)`
  width: 100%;

  &.ant-select-multiple {
    .ant-select-selection-item {
      color: #8e90a6;
      background-color: #fafafc;
      border-color: #e3e4eb;
      border-radius: 12px;
      padding: 0 8px;

      .ant-select-selection-item-content {
        font-weight: 700;
      }

      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }
    }
  }

  &.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    color: inherit;
  }
`
