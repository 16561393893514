import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './Button.styled'

export const Button = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

Button.propTypes = {
  children: PropTypes.node.isRequired
}
