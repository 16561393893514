import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MdExpandMore, MdOutlineClose } from 'react-icons/md'

import { Loading } from 'foundation'
import { Wrapper } from './Select.styled'

const Select = ({ children, loading, ...props }) => {
  return (
    <Wrapper
      suffixIcon={loading ? <Loading /> : <MdExpandMore size='24px' />}
      removeIcon={<MdOutlineClose fill='#8E90A6' size='16px' />}
      filterOption={(input, option) => {
        let value = option?.options || option.label

        value = _.isArray(value) ? _.map(value, ['label']).join('') : value

        return (
          value?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
        )
      }}
      className='nodrag'
      {...props}
    >
      {children}
    </Wrapper>
  )
}

Select.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool
}

Select.Option = Wrapper.Option
Select.OptGroup = Wrapper.OptGroup

export { Select }
