import styled from 'styled-components'

export const Wrapper = styled.div.attrs({ className: 'flex' })`
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid var(--bg-light-1);
  background-color: var(--bg-light-3);
  overflow: hidden;
`

export const TypeBadge = styled.div.attrs({ className: 'flex flex-center' })`
  color: var(--text-dark-3);
  padding: 6px 12px;
  gap: 8px;
  border-right: 1px solid var(--bg-light-1);
`

export const Icon = styled.span`
  display: flex;
  font-size: 16px;
`

export const Text = styled.span`
  font-weight: 500;
`

export const Input = styled.input`
  border: 0;
  padding-left: 8px;
  width: 100%;

  &:disabled {
    background-color: var(--bg-light-3);
  }

  ::-webkit-input-placeholder {
    color: var(--text-dark-3);
  }
  :-moz-placeholder {
    color: var(--text-dark-3);
    opacity: 1;
  }
  ::-moz-placeholder {
    color: var(--text-dark-3);
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: var(--text-dark-3);
  }
  ::-ms-input-placeholder {
    color: var(--text-dark-3);
  }
  ::placeholder {
    color: var(--text-dark-3);
  }
`
