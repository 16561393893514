import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { IconContext } from 'react-icons'

import App from './App'
import GlobalStyles from 'GlobalStyles'
import { store } from './store'
import { Theme } from 'Theme'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Theme>
    <Provider store={store}>
      <GlobalStyles />
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <App />
      </IconContext.Provider>
    </Provider>
  </Theme>
)
