import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './Container.styled'

export const Container = ({ children, size = 'xl', ...props }) => {
  return (
    <Wrapper {...props} className={`container container-${size}`}>
      {children}
    </Wrapper>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string
}
