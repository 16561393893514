import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px 0;
  margin-bottom: 16px;
  width: 100%;

  .ant-input {
    padding: 12px;
    font-size: 16px;
  }
`
