import styled from 'styled-components'
import { Card, Space as AntSpace } from 'antd'
import waves from 'assets/images/waves.svg'

export const Wrapper = styled.div`
  display: block;
  background: var(--bg-light-3);
  height: 100%;
  width: 100%;
`

export const BackgroundLayer = styled.div`
  height: 300px;
  margin-bottom: -300px;
  width: 100%;
  background-color: var(--primary);
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    widht: 100%;
    height: 100%;
    background-image: url(${waves});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    opacity: 0.05;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 576px;
  margin: 0 auto;
`

export const Logo = styled.img`
  margin: 60px 0;
`

export const LoginForm = styled(Card)`
  width: calc(100% - 64px);
  border-radius: 8px;
  box-shadow: 0 1px 0px #f0f0f0, 0 2px 4px #f0f0f0;
`

export const Space = styled(AntSpace)`
  display: flex;
`

export const Footer = styled.div`
  margin-top: 32px;
`

export const BackToDashboard = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.65;
  }

  a {
    color: #0a0d36;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const Version = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: #8e90a6;
  font-family: monospace;
`
