import { request } from 'utils'

export const flow = {
  getFlows: () => {
    const accountUuid = localStorage.getItem('accountUuid')

    return request.get(`/accounts/${accountUuid}/flow/`).then((res) => res.data)
  },

  getFlow(flowUuid) {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .get(`/accounts/${accountUuid}/flow/${flowUuid}/`)
      .then((res) => res.data)
  },

  createFlow(payload) {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .post(`/accounts/${accountUuid}/flow/`, { is_active: false, ...payload })
      .then((res) => res.data)
  },

  updateFlow(flowUuid, payload) {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .patch(`/accounts/${accountUuid}/flow/${flowUuid}/`, payload)
      .then((res) => res.data)
  }
}
