import { actionTypes } from '../actionTypes'

const initialState = {
  flows: [],
  channels: [],
  agents: [],
  agentGroups: [],
  conversationTypes: [],
  isFetching: {
    flows: true,
    channels: true,
    agents: true,
    agentGroups: true,
    conversationTypes: true
  },
  isFetched: {
    flows: false,
    channels: false,
    agents: false,
    agentGroups: false,
    conversationTypes: false
  }
}

const handleFetchState = (name, isFetching, isFetched, state) => {
  return {
    isFetching: {
      ...state.isFetching,
      [name]: isFetching
    },
    isFetched: {
      ...state.isFetched,
      [name]: isFetched
    }
  }
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_START:
      return {
        ...state,
        ...handleFetchState(action.name, true, false, state)
      }
    case actionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        [action.name]: action.payload,
        ...handleFetchState(action.name, false, true, state)
      }
    case actionTypes.FETCH_ACCOUNT_FAIL:
      return {
        error: action.payload.err,
        ...handleFetchState(action.name, false, false, state)
      }
    default:
      return state
  }
}
