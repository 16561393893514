import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import _ from 'lodash'

import { channelData, generateNewFlowName } from 'utils'
import { Modal, Input, Select, Button, Container } from 'foundation'

import { Bar, Description, Title, Wrapper } from './Header.styled'
import { createFlow, fetchChannels, logout } from 'store/actions'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'foundation'
import { MdOutlineMoreVert } from 'react-icons/md'

export const Header = () => {
  const defaultValue = generateNewFlowName()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { channels, isFetching, isFetched } = useSelector(
    (state) => state.account
  )
  const { create, uuid } = useSelector((state) => state.flow)

  const [modalVisibility, setModalVisibility] = useState(false)
  const [name, setName] = useState(defaultValue)
  const [selectedChannels, setSelectedChannels] = useState([])

  const items = [
    {
      label: 'Sign out',
      key: 'saveas',
      onClick: () => onLogout()
    }
  ]

  useEffect(() => {
    if (modalVisibility && !isFetched.channels) {
      dispatch(fetchChannels())
    }
  }, [modalVisibility])

  useEffect(() => {
    if (create.status === 'success' && uuid) {
      navigate(`/workspace/${uuid}`)
    }
  }, [create.status])

  const options = () => {
    const activeChannels = _.filter(channels, ['is_active', true])

    return _.chain(activeChannels)
      .groupBy('type')
      .map((channel, key) => ({
        label: channelData[key].display,
        options: _.map(channel, (c) => ({
          label: c.name,
          value: c.uuid
        }))
      }))
      .value()
  }

  const onChange = (event) => {
    const { value } = event.target

    setName(value)
  }

  const onSelect = (value) => {
    setSelectedChannels((prevState) => _.concat(prevState, value))
  }

  const onDeselect = (value) => {
    setSelectedChannels((prevState) => _.filter(prevState, (i) => i !== value))
  }

  const onCreate = () => {
    dispatch(createFlow({ name, channels: selectedChannels }))
  }

  const onLogout = async () => {
    await dispatch(logout())

    setTimeout(() => navigate('/'), 200)
  }

  return (
    <Wrapper>
      <Container size='sm'>
        <Bar>
          <div>
            <Title>Flows</Title>
            <Description>
              You can update, add new chatbot or disable them.
            </Description>
          </div>
          <div className='flex flex-center' style={{ gap: 16 }}>
            <Button onClick={() => setModalVisibility(true)}>
              Create a new flow
            </Button>
            <Dropdown trigger='click' menu={{ items }}>
              <Button type='icon'>
                <MdOutlineMoreVert />
              </Button>
            </Dropdown>
          </div>
          <Modal
            open={modalVisibility}
            onVisibility={setModalVisibility}
            title='Create a New Flow'
            okText='Create'
            onOk={onCreate}
            okButtonProps={{
              disabled: !name.trim().length || !selectedChannels.length,
              loading: create.status === 'loading'
            }}
          >
            <Form layout='vertical'>
              <Form.Item label='Flow Name'>
                <Input
                  value={name}
                  placeholder={defaultValue}
                  onChange={onChange}
                />
              </Form.Item>
              <Form.Item label='Select Channels'>
                <Select
                  allowClear
                  showSearch
                  mode='multiple'
                  placeholder='Select channels'
                  loading={isFetching.channels}
                  options={options()}
                  value={selectedChannels}
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                />
              </Form.Item>
            </Form>
          </Modal>
        </Bar>
      </Container>
    </Wrapper>
  )
}
