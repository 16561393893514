/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { auth } from 'services'
import { fetchFlows } from 'store/actions'

export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFlows())
  }, [])

  return auth.loggedIn() ? children : <Navigate to='/' />
}
