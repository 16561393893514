import { actionTypes } from '../actionTypes'
import { flow } from 'services'
import { initialElements } from './elementsActions'

export const fetchFlowStart = () => (dispatch) => {
  return dispatch({ type: actionTypes.FETCH_FLOW_START })
}

export const fetchFlowSuccess = (payload) => (dispatch) => {
  return dispatch({
    type: actionTypes.FETCH_FLOW_SUCCESS,
    payload
  })
}

export const fetchFlowError = (err) => (dispatch) => {
  return dispatch({
    type: actionTypes.FETCH_FLOW_FAIL,
    payload: { err }
  })
}

export const fetchFlow = (flowUuid) => (dispatch) => {
  dispatch(fetchFlowStart())

  return flow
    .getFlow(flowUuid)
    .then((response) => {
      dispatch(fetchFlowSuccess(response))
      dispatch(initialElements(response?.schema || {}))

      return response
    })
    .catch((error) => dispatch(fetchFlowError(error)))
}

/* CREATE ACTIONS */
export const createFlowStart = () => (dispatch) => {
  return dispatch({ type: actionTypes.CREATE_FLOW_START })
}

export const createFlowSuccess = (payload) => (dispatch) => {
  return dispatch({
    type: actionTypes.CREATE_FLOW_SUCCESS,
    payload
  })
}

export const createFlowError = (err) => (dispatch) => {
  return dispatch({
    type: actionTypes.CREATE_FLOW_FAIL,
    payload: { err }
  })
}

export const createFlow = (payload) => (dispatch) => {
  dispatch(createFlowStart())

  return flow
    .createFlow(payload)
    .then((response) => {
      dispatch(createFlowSuccess(response))

      return response
    })
    .catch((error) => dispatch(createFlowError(error)))
}

/* UPDATE ACTIONS */
export const updateFlowStart = () => (dispatch) => {
  return dispatch({ type: actionTypes.UPDATE_FLOW_START })
}

export const updateFlowSuccess = (payload) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_FLOW_SUCCESS,
    payload
  })
}

export const updateFlowError = (err) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_FLOW_FAIL,
    payload: { err }
  })
}

export const updateFlow = (flowUuid, payload) => (dispatch) => {
  dispatch(updateFlowStart())

  return flow
    .updateFlow(flowUuid, payload)
    .then((response) => {
      dispatch(updateFlowSuccess(response))

      return response
    })
    .catch((error) => dispatch(updateFlowError(error)))
}
