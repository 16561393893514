import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import _ from 'lodash'
import {
  MdOutlineOpenInNew,
  MdOutlineVisibility,
  MdOutlineVisibilityOff
} from 'react-icons/md'
import packageJson from '../../../package.json'

import { auth } from 'services'
import { login } from 'store/actions'
import { Input, Button } from 'foundation'
import {
  Wrapper,
  BackgroundLayer,
  Content,
  Logo,
  LoginForm,
  Space,
  Footer,
  BackToDashboard,
  Version
} from './Home.styles'
import srcLogo from 'assets/images/connexease.svg'

const __Home = ({ dispatch, state }) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (auth.loggedIn()) {
      navigate('/list')
    }
  }, [auth.loggedIn()])

  const onKeyPress = (event) => {
    const ENTER_KEY_CODE = 13

    if (
      username &&
      password &&
      (event.keyCode === ENTER_KEY_CODE || event.which === ENTER_KEY_CODE)
    ) {
      dispatch(login({ username, password }))
    }
  }

  useEffect(() => {
    if (state?.login?.error) {
      const { status } = state.login?.error?.response || {}
      const messageKey = 'loginWarning'

      if (status === 400) {
        message.error({
          content: 'Incorrect username or password. Please try again',
          key: messageKey
        })
      } else if (status === 403) {
        message.error({
          content:
            'Your account is not enabled. Please contact your account manager!',
          key: messageKey
        })
      } else {
        message.error({
          content: 'Unable to login',
          key: messageKey
        })
      }
    }
  }, [state?.login?.error])

  const disabledLoginButton = () => {
    return _.isEmpty(username) || _.isEmpty(password)
  }

  return (
    <Wrapper>
      <BackgroundLayer />
      <Content>
        <Logo src={srcLogo} alt='logo' width={300} />

        <LoginForm onKeyPress={onKeyPress}>
          <Space direction='vertical' width='100%' size={16}>
            <Input
              allowClear
              size='large'
              placeholder='Username'
              value={username}
              onChange={(event) => {
                setUsername(event.currentTarget.value)
              }}
            />
            <Input.Password
              size='large'
              placeholder='Password'
              value={password}
              iconRender={(visible) =>
                visible ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />
              }
              onChange={(event) => setPassword(event.currentTarget.value)}
            />
            <Button
              block
              type='primary'
              loading={state.login.isFetching}
              onClick={() => dispatch(login({ username, password }))}
              disabled={disabledLoginButton()}
            >
              Login
            </Button>
          </Space>
        </LoginForm>
        <Footer>
          <BackToDashboard>
            <MdOutlineOpenInNew size={18} />
            <a
              href='https://app.connexease.com/'
              target='_blank'
              rel='noreferrer'
            >
              Back to Dashboard
            </a>
          </BackToDashboard>
        </Footer>
      </Content>
      <Version>v{packageJson.version}</Version>
    </Wrapper>
  )
}

export const Home = connect((state) => ({ state }))(__Home)
