import styled from 'styled-components'
import { Input } from 'antd'

export const Wrapper = styled(Input)`
  padding: 12px;
  border-radius: 4px;
  max-height: 42px;

  textarea.ant-input {
    border-radius: 8px;
  }
`
