import React from 'react'
import PropTypes from 'prop-types'
import { Handle } from 'reactflow'
import _ from 'lodash'

export const LeftHandle = ({ isFirst }) => {
  if (isFirst) {
    return null
  }

  return <Handle id='left' type='target' position='left' style={{ top: 26 }} />
}

export const RightHandle = ({ data, isConnectable }) => {
  if (!_.isEmpty(data?.bot?.option)) {
    return null
  }

  return (
    <Handle
      id='right'
      type='source'
      position='right'
      isConnectable={isConnectable}
    />
  )
}

LeftHandle.propTypes = {
  isFirst: PropTypes.bool
}

RightHandle.propTypes = {
  data: PropTypes.object,
  isConnectable: PropTypes.bool
}
