import styled from 'styled-components'
import { up } from 'styled-breakpoints'

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-right: auto;
  margin-left: auto;

  &.container {
    &.container-tight {
      max-width: 30rem;
    }

    &.container-sm {
      ${up('sm')} {
        max-width: 540px;
      }
    }

    &.container-sm,
    &.container-md {
      ${up('md')} {
        max-width: 720px;
      }
    }

    &.container-sm,
    &.container-md,
    &.container-lg {
      ${up('lg')} {
        max-width: 960px;
      }
    }

    &.container-sm,
    &.container-md,
    &.container-lg,
    &.container-xl {
      ${up('xl')} {
        max-width: 1140px;
      }
    }

    &.container-sm,
    &.container-md,
    &.container-lg,
    &.container-xl,
    &.container-xxl {
      ${up('xxl')} {
        max-width: 1320px;
      }
    }
  }
`
