import styled, { css } from 'styled-components'

export const LoadingWrapper = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const ModalGlobalStyles = css`
  .ant-modal:not(.ant-modal-confirm) {
    .ant-modal-content {
      border-radius: 8px;
      box-shadow: 0px 4px 32px rgba(8, 10, 62, 0.25);

      .ant-modal-header {
        padding: 40px 40px 0 40px;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;

        .ant-modal-title {
          font-size: 20px;
          font-weigth: 500;
          color: #1c1c28;
        }
      }

      .ant-modal-body {
        padding: 24px 40px 0 40px;

        .ant-form {
          .ant-form-item:last-child {
            margin-bottom: 0;
          }

          .ant-form-item-label {
            > label {
              font-weight: 500;
              font-size: 16px;

              .ant-form-item-optional {
                font-size: 14px;
                font-style: italic;
                color: #c7c8d9;
              }
            }
          }
        }
      }

      .ant-modal-footer {
        padding: 24px 40px 40px 40px;
        border-top: 0;
        border-radius: 0 0 8px 8px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        > button.ant-btn {
          flex: 1;
          height: unset;
          padding: 12px;

          & + button.ant-btn {
            flex: 2;
            margin-left: 12px;
          }

          &.ant-btn-default {
            border-color: #0a0d36;
            color: #0a0d36;
          }
        }
      }
    }

    &.ant-modal-fullscreen {
      width: 100% !important;
      max-width: 100vw;
      height: 100vh;
      top: 0;
      margin: 0 !important;
      padding-bottom: 0;

      .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        border-radius: 0;

        .ant-modal-header {
          background-color: #0a0d36;
          padding: 24px 16px;
          border-radius: 0;

          .ant-modal-title {
            font-weight: 500;
            color: #e3e4eb;
          }
        }

        .ant-modal-body {
          padding: 16px;
          height: 100%;
          flex: 1 1 100%;
          overflow-y: scroll;
        }
      }
    }
  }
`
