import React from 'react'
import { Table as AntTable } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Wrapper } from './Table.styled'
import { Loading } from 'foundation'

export const Table = () => {
  const account = useSelector((state) => state.account)

  const columns = [
    {
      title: 'Order',
      dataIndex: 'key',
      key: 'key',
      render: (key) => <div>{key}</div>
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Link to={`/workspace/${record.uuid}`}>{name || record.uuid}</Link>
      )
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (is_active) => (is_active ? <b>Active</b> : 'Passive')
    }
  ]

  return (
    <Wrapper>
      {account.isFetched.flows ? (
        <AntTable
          columns={columns}
          dataSource={account.flows}
          pagination={{ defaultPageSize: 50 }}
        />
      ) : (
        <Loading size='32px' />
      )}
    </Wrapper>
  )
}
