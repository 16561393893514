import { Input } from 'foundation'
import React from 'react'
import { Wrapper } from './Search.styled'

export const Search = () => {
  return (
    <Wrapper>
      <Input placeholder='Search flow...' />
    </Wrapper>
  )
}
