import React from 'react'

import { Container } from 'foundation'
import { Header, Search, Table } from 'components/List'
import { Wrapper } from './List.styled'

export const List = () => {
  return (
    <Wrapper>
      <Header />
      <Container size='sm'>
        <Search />
        <Table />
      </Container>
    </Wrapper>
  )
}
