import React from 'react'
import PropTypes from 'prop-types'
import { Menu as AntMenu } from 'antd'

import { Wrapper } from './Menu.styled'

const Menu = ({ children, ...props }) => {
  return <Wrapper {...props}>{children || null}</Wrapper>
}

Menu.Item = AntMenu.Item
Menu.SubMenu = AntMenu.SubMenu
Menu.Divider = AntMenu.Divider

Menu.propTypes = {
  children: PropTypes.node.isRequired
}

export { Menu }
