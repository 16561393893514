import styled from 'styled-components'

export const Wrapper = styled.div`
  background: var(--primary);
  color: var(--white);
  padding: 24px;
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: no-wrap;
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
`

export const Description = styled.div`
  font-size: 12px;
  color: var(--text-dark-4);
`
