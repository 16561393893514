import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

export const Loading = ({ size, ...props }) => (
  <Spin
    {...props}
    indicator={
      <LoadingOutlined style={{ fontSize: size, color: '#3860EE' }} spin />
    }
  />
)

Loading.propTypes = {
  size: PropTypes.any
}
