import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'foundation'
import { Title, Wrapper } from './NotFound.styled'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <h1>404 Not Found</h1>
      <Title>Sorry, the page you visited does not exist.</Title>
      <Button type='primary' onClick={() => navigate('/')}>
        Back Home
      </Button>
    </Wrapper>
  )
}
