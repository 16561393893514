import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: var(--bg-light-4);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--bg-light-1);
`

export const Back = styled(Link).attrs({ className: 'flex flex-center' })`
  width: 134px;
  height: 79px;
  border: 0;
  outline: 0;
  color: var(--text-dark-3);
  background-color: var(--bg-light-4);
  border-right: 1px solid var(--bg-light-1);
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
`

export const BackIcon = styled.span.attrs({ className: 'flex flex-center' })`
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: var(--primary);
  color: var(--bg-light-1);
  margin-right: 12px;
`

export const Container = styled.div.attrs({ className: 'flex' })`
  padding: 16px 24px;
  flex: 1;
`

export const Content = styled.div`
  flex: 1;
`

export const Name = styled(Typography.Paragraph).attrs({
  className: 'flex items-center'
})`
  font-size: 20px;
  font-weight: 500;
  margin: 0 !important;

  .ant-typography-edit {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-light-1) !important;
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
`

export const Description = styled.div`
  color: var(--text-dark-2);
`

export const ActionButtons = styled.div.attrs({ className: 'flex-center' })`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;

  .ant-btn-primary {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
