import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchFlow } from 'store/actions'
import { Flow, Navbar, Preview } from 'components'
import { Wrapper, PreviewButton, PreviewFrame } from './Workspace.styled'
import { uuidValidator } from 'utils'

export const Workspace = () => {
  const navigate = useNavigate()
  const { flowUuid } = useParams()

  const dispatch = useDispatch()
  const { flow } = useSelector((state) => state)
  const { uuid, data, isFetched } = flow

  const [preview, setPreview] = useState(false)
  const [flowData, setFlowData] = useState(data)

  useEffect(() => {
    setFlowData(data)
  }, [uuid, isFetched, data])

  useEffect(() => {
    if (uuidValidator(flowUuid)) {
      dispatch(fetchFlow(flowUuid))
    } else {
      navigate('/list')
    }
  }, [flowUuid])

  return (
    <Wrapper>
      <Navbar />
      <Flow />

      <PreviewButton show={preview} onClick={() => setPreview(!preview)}>
        <div className={preview ? 'active' : 'default'}></div>
      </PreviewButton>

      {preview && flowData && (
        <PreviewFrame>
          <Preview flow={flowData} />
        </PreviewFrame>
      )}
    </Wrapper>
  )
}
