import { actionTypes } from '../actionTypes'

const initialState = {
  accountUuid: null,
  token: null,
  isFetching: false
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        accountUuid: action.payload.accountUuid,
        token: action.payload.token,
        isFetching: false
      }
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        accountUuid: null,
        token: null,
        error: action.payload.err,
        isFetching: false
      }
    case actionTypes.LOGIN_CHECK:
      return {
        ...state,
        accountUuid: action.payload.accountUuid,
        token: action.payload.token,
        isFetching: false
      }

    case actionTypes.LOGOUT:
      return initialState
    default:
      return state
  }
}
