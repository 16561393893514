import { request } from 'utils'

export const auth = {
  jwt: (data) => {
    return request.post('/jwt/', data).then((res) => res.data)
  },
  loggedIn() {
    return !!(localStorage.token && localStorage.accountUuid)
  }
}
