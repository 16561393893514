import styled from 'styled-components'
import { Button } from 'antd'

export const Wrapper = styled(Button)`
  height: unset;
  /* max-height: 40px; */
  border-width: 2px;
  padding: 8px 12px;

  &.ant-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 2px;
    border-radius: 100%;
    border: 0;
    background-color: var(--bg-light-1);

    &:hover,
    &:active {
      background-color: var(--bg-dark-4);
    }

    .react-icons {
      width: 20px;
      height: 20px;
      color: var(--text-dark);
    }
  }

  &[disabled],
  &:hover[disabled] {
    background-color: var(--bg-light-1);
    border-color: var(--bg-light-1);
  }
`
