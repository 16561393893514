import styled, { css } from 'styled-components'
import { Dropdown } from 'antd'

export const DropdownGlobalStyles = css`
  .ant-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        padding: 8px 12px;
      }
    }
  }
`

export const Wrapper = styled(Dropdown)``
