import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Home, Workspace, NotFound, List } from 'views'
import { PrivateRoute } from 'views/PrivateRoute'
import { Wrapper } from './App.styled'

function App() {
  return (
    <Wrapper>
      <Router>
        <Routes>
          <Route path='/'>
            <Route path=':flowUuid' element={<Home />} />
            <Route path='' element={<Home />} />
          </Route>

          <Route
            path='list'
            element={
              <PrivateRoute>
                <List />
              </PrivateRoute>
            }
          />

          <Route
            path='workspace/:flowUuid'
            element={
              <PrivateRoute>
                <Workspace />
              </PrivateRoute>
            }
          />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </Wrapper>
  )
}

export default App
