import { request } from 'utils'

export const account = {
  getChannels: () => {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .get(`/accounts/${accountUuid}/channels/`)
      .then((res) => res.data)
  },
  getAgents: () => {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .get(`/accounts/${accountUuid}/agents/`)
      .then((res) => res.data)
  },
  getAgentGroups: () => {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .get(`/accounts/${accountUuid}/agentgroups/`)
      .then((res) => res.data)
  },
  getConversationTypes: () => {
    const accountUuid = localStorage.getItem('accountUuid')

    return request
      .get(`/accounts/${accountUuid}/conversationtypes/`)
      .then((res) => res.data)
  }
}
