import { actionTypes } from '../actionTypes'

const initialState = {
  id: null,
  name: null,
  uuid: null,
  channels: [],
  data: {},
  is_active: null,
  isFetching: true,
  isFetched: false,
  update: {},
  create: {}
}

export default function flowReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FLOW_START:
      return initialState

    case actionTypes.FETCH_FLOW_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true
      }

    case actionTypes.FETCH_FLOW_FAIL:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: action.payload.err
      }

    case actionTypes.UPDATE_FLOW_START:
      return {
        ...state,
        update: {
          status: 'loading',
          message: 'Flow is updating...'
        }
      }

    case actionTypes.UPDATE_FLOW_SUCCESS:
      return {
        ...state,
        ...action.payload,
        update: {
          status: 'success',
          message: 'Flow is updated!'
        }
      }

    case actionTypes.UPDATE_FLOW_FAIL:
      return {
        ...state,
        error: action.payload.err,
        update: {
          status: 'error',
          message: 'An error has occurred!'
        }
      }

    case actionTypes.CREATE_FLOW_START:
      return {
        ...state,
        create: {
          status: 'loading',
          message: 'Flow is creating...'
        }
      }

    case actionTypes.CREATE_FLOW_SUCCESS:
      return {
        ...state,
        ...action.payload,
        create: {
          status: 'success',
          message: 'Flow is created!'
        }
      }

    case actionTypes.CREATE_FLOW_FAIL:
      return {
        ...state,
        error: action.payload.err,
        create: {
          status: 'error',
          message: 'An error has occurred!'
        }
      }

    default:
      return state
  }
}
