import React from 'react'
import PropTypes from 'prop-types'
import { Modal as AntModal } from 'antd'

import { Loading } from 'foundation'
import { LoadingWrapper } from './Modal.styled'

const Modal = ({ children, onVisibility, loading, ...props }) => {
  return (
    <AntModal
      maskStyle={{ backgroundColor: 'rgba(10, 13, 54, .5)' }}
      onCancel={() => onVisibility(false)}
      closable={false}
      {...props}
    >
      {loading ? (
        <LoadingWrapper>
          <Loading size='32px' />
        </LoadingWrapper>
      ) : (
        children
      )}
    </AntModal>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  onVisibility: PropTypes.func,
  loading: PropTypes.bool
}

Modal.info = AntModal.info
Modal.success = AntModal.success
Modal.error = AntModal.error
Modal.warning = AntModal.warning
Modal.confirm = AntModal.confirm
Modal.destroyAll = AntModal.destroyAll

export { Modal }
