import _ from 'lodash'

import { actionTypes } from '../actionTypes'
import { account, flow } from 'services'

export const fetchAccountStart = (name) => (dispatch) => {
  return dispatch({
    type: actionTypes.FETCH_ACCOUNT_START,
    name
  })
}

export const fetchAccountSuccess = (response, name) => (dispatch) => {
  return dispatch({
    type: actionTypes.FETCH_ACCOUNT_SUCCESS,
    payload: response,
    name
  })
}

export const fetchAccountError = (err, name) => (dispatch) => {
  return dispatch({
    type: actionTypes.FETCH_ACCOUNT_FAIL,
    payload: { err },
    name
  })
}

export const fetchFlows = () => (dispatch) => {
  const name = 'flows'

  dispatch(fetchAccountStart(name))

  return flow
    .getFlows()
    .then((response) => {
      const ordered = _.orderBy(response, 'id')
      const data = _.map(ordered, (f, i) => ({
        key: i + 1,
        ...f
      }))
      const nextFlowId = data.length + 1

      localStorage.setItem('next-flow-id', nextFlowId)

      dispatch(fetchAccountSuccess(data, name))

      return data
    })
    .catch((error) => dispatch(fetchAccountError(error, name)))
}

export const fetchChannels = () => (dispatch) => {
  const name = 'channels'

  dispatch(fetchAccountStart(name))

  return account
    .getChannels()
    .then((response) => {
      dispatch(fetchAccountSuccess(response, name))

      return response
    })
    .catch((error) => dispatch(fetchAccountError(error, name)))
}

export const fetchAgents = () => (dispatch) => {
  const name = 'agents'

  dispatch(fetchAccountStart(name))

  return account
    .getAgents()
    .then((response) => {
      dispatch(fetchAccountSuccess(response, name))

      return response
    })
    .catch((error) => dispatch(fetchAccountError(error, name)))
}

export const fetchAgentGroups = () => (dispatch) => {
  const name = 'agentGroups'

  dispatch(fetchAccountStart(name))

  return account
    .getAgentGroups()
    .then((response) => {
      dispatch(fetchAccountSuccess(response, name))

      return response
    })
    .catch((error) => dispatch(fetchAccountError(error, name)))
}

export const fetchConversationTypes = () => (dispatch) => {
  const name = 'conversationTypes'

  dispatch(fetchAccountStart(name))

  return account
    .getConversationTypes()
    .then((response) => {
      dispatch(fetchAccountSuccess(response, name))

      return response
    })
    .catch((error) => dispatch(fetchAccountError(error, name)))
}
