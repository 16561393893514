import { message as AntMessage } from 'antd'
import _ from 'lodash'

const generateMessage = (content, duration, onClose, type) => {
  let className = `ant-message__${type}`
  let config = {
    className,
    content,
    duration,
    onClose,
    ...content
  }

  if (_.isObject(content)) {
    config = { className, ...content }
  }

  return AntMessage[type](config)
}

export const message = {
  info: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, 'info'),
  success: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, 'success'),
  error: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, 'error'),
  warning: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, 'warning'),
  loading: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, 'loading'),
  open: (content, duration, onClose) =>
    generateMessage(content, duration, onClose, content?.type),
  destory: AntMessage.destroy
}
