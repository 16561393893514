import loginTypes from './loginTypes'
import accountTypes from './accountTypes'
import flowTypes from './flowTypes'
import elementsTypes from './elementsTypes'

export const actionTypes = {
  ...loginTypes,
  ...accountTypes,
  ...flowTypes,
  ...elementsTypes
}
