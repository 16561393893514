import styled from 'styled-components'
import { Menu } from 'antd'

export const Wrapper = styled(Menu)`
  padding: 0;
  border-radius: 8px;
  border: 1px solid rgba(227, 228, 235, 0.35);
  overflow: hidden;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 10px 12px;

    .ant-dropdown-menu-title-content {
      color: #555770;
      font-weight: 500;
    }

    .ant-dropdown-menu-item-icon {
      margin-right: 12px;
    }
  }

  .agent-status-menu .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
`
