import { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow'
import _ from 'lodash'

import { actionTypes } from '../actionTypes'

const initialState = {
  instance: {},
  nodes: [],
  edges: [],
  viewport: { x: 0, y: 0, zoom: 1 }
}

export default function elementsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INITIAL_ELEMENTS:
      return {
        ...initialState,
        instance: state.instance,
        ...action.payload
      }

    case actionTypes.SET_INSTANCE:
      return {
        ...state,
        instance: action.instance
      }

    case actionTypes.ADD_NODE:
      return {
        ...state,
        nodes: state.nodes.concat(action.node)
      }

    case actionTypes.DELETE_NODE: {
      const { nodeId } = action
      const edges = _.filter(state.edges, (edge) => {
        return edge.source !== nodeId && edge.target !== nodeId
      })

      let nodes = _.filter(state.nodes, (node) => node.id !== nodeId)
      let noFirstNode = _.find(nodes, ['data.isFirst', false])

      if (noFirstNode) {
        nodes = _.map(nodes, (node, key) => {
          if (key === 0) {
            return { ...node, data: { ...node.data, isFirst: true } }
          }

          return node
        })
      }

      return {
        ...state,
        edges,
        nodes: nodes
      }
    }

    case actionTypes.UPDATE_NODE_NAME: {
      const { nodeId, name } = action

      return {
        ...state,
        nodes: _.map(state.nodes, (node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                label: name
              }
            }
          }

          return node
        })
      }
    }

    case actionTypes.UPDATE_NODE_BOT_DATA: {
      const { nodeId, data } = action

      return {
        ...state,
        nodes: _.map(state.nodes, (node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                bot: {
                  ...node.data.bot,
                  ...data
                }
              }
            }
          }

          return node
        })
      }
    }

    case actionTypes.HANDLE_CHANGE_NODES:
      return {
        ...state,
        nodes: applyNodeChanges(action.changes, state.nodes)
      }

    case actionTypes.HANDLE_CHANGE_EDGES:
      return {
        ...state,
        edges: applyEdgeChanges(action.changes, state.edges)
      }

    case actionTypes.HANDLE_CONNECT:
      return {
        ...state,
        edges: addEdge(action.connection, state.edges)
      }

    case actionTypes.SET_VIEWPORT:
      return {
        ...state,
        viewport: action.viewport
      }

    case actionTypes.UPDATE_NODE_DATA: {
      const { nodeId, data, key } = action

      return {
        ...state,
        nodes: _.map(state.nodes, (node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                [key]: data
              }
            }
          }

          return node
        })
      }
    }

    default:
      return state
  }
}
