/* eslint-disable react/prop-types */
import React from 'react'

import { ConnectionLineType, getSmoothStepPath } from 'reactflow'

export const ConnectionLine = {
  connectionLineType: ConnectionLineType.SmoothStep,
  connectionLineStyle: {
    stroke: 'var(--secondary-light)',
    strokeWidth: 2
  }
}

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  return (
    <>
      <path
        id={id}
        style={{ stroke: 'var(--secondary-light)', strokeWidth: 2 }}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
    </>
  )
}
