import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { createTheme } from 'styled-breakpoints'

import { breakpoints } from 'utils'

const theme = createTheme(breakpoints)

export const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.node.isRequired
}
