import { actionTypes } from '../actionTypes'
import { auth } from 'services'

export const login =
  ({ username, password }) =>
  (dispatch) => {
    dispatch(loginStart())

    return auth
      .jwt({
        isLogin: true,
        username,
        password
      })
      .then((response) => {
        // Save token to local storage
        localStorage.token = response.token
        localStorage.accountUuid = response.account.uuid

        dispatch(
          loginSuccess({
            accountUuid: response.account.uuid,
            token: response.token
          })
        )
        return response
      })
      .catch((error) => dispatch(loginError(error)))
  }

export const loginStart = () => (dispatch) => {
  return dispatch({
    type: actionTypes.LOGIN_START
  })
}

export const loginSuccess = (uuid) => (dispatch) => {
  return dispatch({
    type: actionTypes.LOGIN_SUCCESS,
    payload: uuid
  })
}

export const loginError = (err) => (dispatch) => {
  return dispatch({
    type: actionTypes.LOGIN_FAIL,
    payload: { err }
  })
}

export const loginCheck = () => (dispatch) => {
  const { token, accountUuid } = localStorage

  return dispatch({
    type: actionTypes.LOGIN_CHECK,
    payload: {
      isLogin: token && accountUuid,
      token: token,
      accountUuid: accountUuid
    }
  })
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('token')
  localStorage.removeItem('accountUuid')
  localStorage.removeItem('next-flow-id')

  return dispatch({
    type: actionTypes.LOGOUT
  })
}
