import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './Dropdown.styled'

export const Dropdown = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired
}
