import { createGlobalStyle } from 'styled-components'

import { DropdownGlobalStyles } from 'foundation/Dropdown/Dropdown.styled'
import { SelectGlobalStyles } from 'foundation/Select/Select.styled'
import { ModalGlobalStyles } from 'foundation/Modal/Modal.styled'

import 'assets/css/main.less'

const GlobalStyles = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    > iframe {
      display: none !important;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  small {
    font-size: 85.71428571%;
  }

  .react-icons {
    vertical-align: middle;
  }

  .react-flow__edge.selected {
    .react-flow__edge-path {
      filter: drop-shadow(0px 0px 2px #3e68ff);
    }
  }

  .react-flow__attribution {
    display: none !important;
  }

  ${ModalGlobalStyles}
  ${DropdownGlobalStyles}
  ${SelectGlobalStyles}
`

export default GlobalStyles
