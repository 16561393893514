import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  Head,
  LeftHandle,
  RightHandle,
  TextInput,
  DroppableWrapper
} from './common'

export const TextBubble = memo(({ id, data, isConnectable }) => {
  return (
    <DroppableWrapper id={id}>
      <LeftHandle isFirst={data.isFirst} />
      <Head id={id} data={data} />
      <TextInput id={id} />
      <RightHandle isConnectable={isConnectable} data={data} />
    </DroppableWrapper>
  )
})

TextBubble.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  isConnectable: PropTypes.bool
}

TextBubble.displayName = 'TextBubble'
