import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  MdArrowBack,
  MdEdit,
  MdOutlineMoreVert,
  MdPublic
} from 'react-icons/md'

import { updateFlow } from 'store/actions'
import { Button, Dropdown, message } from 'foundation'
import {
  ActionButtons,
  Back,
  BackIcon,
  Container,
  Content,
  Description,
  Name,
  Wrapper
} from './Navbar.styled'
import { dataGenerator } from 'utils'

export const Navbar = () => {
  const dispatch = useDispatch()
  const { flow, elements } = useSelector((state) => state)

  const [flowName, setFlowName] = useState('')
  const { instance } = elements
  const { uuid, name, isFetched, update } = flow

  const items = [
    {
      label: 'Save as Draft',
      key: 'saveas',
      onClick: () => onSaveAsDraft()
    },
    {
      type: 'divider'
    },
    {
      label: 'Active',
      key: 'active',
      onClick: () => onStatusChange(true)
    },
    {
      label: 'Deactive',
      key: 'deactive',
      danger: true,
      onClick: () => onStatusChange(false)
    }
  ]

  useEffect(() => {
    if (isFetched) {
      setFlowName(name)
    }
  }, [uuid, isFetched])

  useEffect(() => {
    if (update.status) {
      message[update.status]({
        key: 'flowUpdate',
        content: update.message,
        duration: 0.5
      })
    }

    return () => message.destroy
  }, [update.status])

  const onNameChange = async (value) => {
    if (uuid && value) {
      await dispatch(updateFlow(uuid, { name: value }))

      setFlowName(value)
    }
  }

  const onStatusChange = async (status) => {
    if (uuid) {
      await dispatch(updateFlow(uuid, { is_active: status }))
    }
  }

  const onSaveAsDraft = async () => {
    await dispatch(
      updateFlow(uuid, {
        schema: instance.toObject(),
        is_active: false
      })
    )
  }

  const onPublishNow = async () => {
    await dispatch(
      updateFlow(uuid, {
        data: dataGenerator(instance.toObject()),
        schema: instance.toObject(),
        is_active: true
      })
    )
  }

  return (
    <Wrapper>
      <Back to='/list'>
        <BackIcon>
          <MdArrowBack />
        </BackIcon>
        Back
      </Back>
      <Container>
        <Content>
          <Name
            editable={{
              icon: <MdEdit size={14} />,
              onChange: onNameChange
            }}
          >
            {flowName}
          </Name>
          <Description>
            You can update, add new chatbot or disable them.
          </Description>
        </Content>
        <ActionButtons>
          <Button type='primary' onClick={onPublishNow}>
            Publish Now
            <MdPublic size={18} />
          </Button>
          <Dropdown trigger='click' menu={{ items }}>
            <Button type='icon'>
              <MdOutlineMoreVert />
            </Button>
          </Dropdown>
        </ActionButtons>
      </Container>
    </Wrapper>
  )
}
