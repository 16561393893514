import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  MdOutlineCheck,
  MdOutlineChromeReaderMode,
  MdOutlineDelete,
  MdOutlineForum,
  MdOutlineMenuOpen
} from 'react-icons/md'

import { Input } from 'foundation'
import {
  BubbleDeleteIcon,
  BubbleIcon,
  OptionSelector,
  RoundedButton,
  Title
} from '../Bubbles.styled'
import {
  handleDeleteNode,
  handleUpdateBotData,
  handleUpdateNodeName
} from 'store/actions'
import { getDataByName } from 'utils'

const icon = {
  text: <MdOutlineForum />,
  menu: <MdOutlineMenuOpen />,
  menuText: <MdOutlineChromeReaderMode />
}

export const Head = ({ id, data, type = 'text' }) => {
  const dispatch = useDispatch()
  const { nodes, edges } = useSelector((state) => state.elements)
  const node = _.find(nodes, ['id', id])

  const [name, setName] = useState(data.label)
  const [editBtnVisible, setEditBtnVisible] = useState(false)

  const sourceId = () => {
    const edge = _.find(edges, ['target', id])

    if (edge) {
      return edge.source
    }

    return false
  }

  const onChange = (event) => {
    const { value } = event.target

    setName(value)
  }

  const onBlur = () => {
    setTimeout(() => {
      setEditBtnVisible(false)
      onUpdate()
    }, 100)
  }

  const onFocus = () => {
    setEditBtnVisible(true)
  }

  const onDelete = () => {
    dispatch(handleDeleteNode(id))
  }

  const onPressEnter = (event) => {
    onUpdate()

    event.target.blur()
    setEditBtnVisible(false)
  }

  const onUpdate = () => {
    if (name) {
      dispatch(handleUpdateNodeName(id, name))
    } else {
      setName(node.data.label)
    }
  }

  const onOptionSelect = (option) => {
    dispatch(handleUpdateBotData(id, { option }))
  }

  return (
    <Title>
      <BubbleIcon>{icon[type]}</BubbleIcon>
      {sourceId() && getDataByName(sourceId(), 'options') ? (
        <OptionSelector
          defaultValue={node?.data?.bot?.option}
          options={[
            ...getDataByName(sourceId(), 'options'),
            { label: 'Any', value: 'any' }
          ]}
          placeholder='Select Type'
          onChange={(__, option) => onOptionSelect(option)}
        />
      ) : (
        <div className='name-input-wrapper'>
          <Input
            value={name}
            placeholder={name}
            maxLength={30}
            onChange={onChange}
            onPressEnter={onPressEnter}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      )}

      {editBtnVisible ? (
        <RoundedButton onClick={onUpdate}>
          <MdOutlineCheck />
        </RoundedButton>
      ) : (
        <BubbleDeleteIcon onClick={onDelete}>
          <MdOutlineDelete />
        </BubbleDeleteIcon>
      )}
    </Title>
  )
}

Head.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string
}
