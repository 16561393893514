import { combineReducers } from 'redux'

import login from './loginReducer'
import account from './accountReducer'
import flow from './flowReducer'
import elements from './elementsReducer'

export default combineReducers({
  login,
  account,
  flow,
  elements
})
