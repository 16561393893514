const actionTypes = {
  FETCH_FLOW_START: 'FETCH_FLOW_START',
  FETCH_FLOW_SUCCESS: 'FETCH_FLOW_SUCCESS',
  FETCH_FLOW_FAIL: 'FETCH_FLOW_FAIL',
  CREATE_FLOW_START: 'CREATE_FLOW_START',
  CREATE_FLOW_SUCCESS: 'CREATE_FLOW_SUCCESS',
  CREATE_FLOW_FAIL: 'CREATE_FLOW_FAIL',
  UPDATE_FLOW_START: 'UPDATE_FLOW_START',
  UPDATE_FLOW_SUCCESS: 'UPDATE_FLOW_SUCCESS',
  UPDATE_FLOW_FAIL: 'UPDATE_FLOW_FAIL'
}

export default actionTypes
