import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Head,
  LeftHandle,
  Options,
  RightHandle,
  TextInput,
  DroppableWrapper
} from './common'

export const MenuTextBubble = memo(({ id, data, isConnectable }) => {
  const [name, setValue] = useState(data.label)

  const onNameChange = (event) => {
    const { value } = event.target

    setValue(value)
  }

  return (
    <DroppableWrapper id={id}>
      <LeftHandle isFirst={data.isFirst} />
      <Head
        id={id}
        type='menuText'
        data={data}
        name={name}
        onNameChange={onNameChange}
      />
      <TextInput id={id} />
      <Options id={id} />
      <RightHandle isConnectable={isConnectable} />
    </DroppableWrapper>
  )
})

MenuTextBubble.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  isConnectable: PropTypes.bool
}

MenuTextBubble.displayName = 'MenuTextBubble'
