// eslint-disable-next-line import/no-named-as-default
import ReactFlow from 'reactflow'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
`

export const StyledReactFlow = styled(ReactFlow)`
  background-color: var(--bg-light-3);
`

export const LoaderWrapper = styled.div.attrs({
  className: 'flex flex-center h-100'
})``
