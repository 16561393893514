import React from 'react'
import PropTypes from 'prop-types'
import { ThreeDots } from 'react-loader-spinner'

// Style
import { Wrapper } from './MessageItem.style'

export const MessageItem = ({ message }) => {
  const renderMessage = () => {
    if (message?.flowTyping) {
      return (
        <ThreeDots
          height='20'
          width='20'
          radius='9'
          color='#555770'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          wrapperClassName=''
          visible
        />
      )
    }
    if (message?.timeout) {
      let timeoutMessage = `Timeout set, step (${message.timeout.to}) runs after ${message.timeout.minute} minute`

      timeoutMessage = `${timeoutMessage}${
        message.timeout.minute > 1 ? 's.' : '.'
      }`
      timeoutMessage = `${timeoutMessage}${
        message.timeout?.canceled ? ' (Canceled)' : ''
      }`
      timeoutMessage = `${timeoutMessage}${
        message.timeout?.executed ? ' (Executed)' : ''
      }`

      return timeoutMessage
    }
    return message.text
  }

  return (
    <Wrapper isSystem={message.isSystem} isAction={message.isAction}>
      <div className='message'>{renderMessage()}</div>
    </Wrapper>
  )
}

MessageItem.propTypes = {
  message: PropTypes.object
}
