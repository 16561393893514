const actionTypes = {
  INITIAL_ELEMENTS: 'INITIAL_ELEMENTS',

  SET_INSTANCE: 'SET_INSTANCE',
  SET_VIEWPORT: 'SET_VIEWPORT',

  ADD_NODE: 'ADD_NODE',
  DELETE_NODE: 'DELETE_NODE',

  UPDATE_NODE_NAME: 'UPDATE_NODE_NAME',
  UPDATE_NODE_BOT_DATA: 'UPDATE_NODE_BOT_DATA',
  UPDATE_NODE_DATA: 'UPDATE_NODE_DATA',

  HANDLE_CHANGE_NODES: 'HANDLE_CHANGE_NODES',
  HANDLE_CHANGE_EDGES: 'HANDLE_CHANGE_EDGES',
  HANDLE_CONNECT: 'HANDLE_CONNECT'
}

export default actionTypes
