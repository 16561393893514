import { notification } from 'antd'

export const handleApiErrors = (err) => {
  if (err.response.status === 401) {
    localStorage.token = ''
    localStorage.accountUuid = ''
    window.location.href = '/'
  } else if (err.response.status === 403) {
    window.location.href = '/chat'
  } else if (
    err.response.status === 400 &&
    err.response.data &&
    err.response.data.info
  ) {
    notification.warning({
      message: 'Warning',
      description: err.response.data.error
    })
  }
}
