export const channelData = {
  messenger: {
    display: 'Messenger'
  },
  whatsapp: {
    display: 'WhatsApp'
  },
  mail: {
    display: 'Mail'
  },
  twitter: {
    display: 'Twitter'
  },
  livechat: {
    display: 'Livechat'
  },
  telegram: {
    display: 'Telegram'
  },
  instagram: {
    display: 'Instagram'
  },
  custom: {
    display: 'Custom'
  },
  google_bm: {
    display: 'Google BM'
  },
  trendyol: {
    display: 'Trendyol'
  },
  app_store_reviews: {
    display: 'App Store Review'
  },
  google_play_reviews: {
    display: 'Google Play Review'
  },
  team_chat: {
    display: 'Team Chat'
  }
}
