import styled from 'styled-components'

export const ActionMenuWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;

  background-color: var(--bg-light-4);

  max-width: 270px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  box-shadow: 0px 2px 6px rgba(8, 10, 62, 0.1);
`

export const ActionSection = styled.div`
  padding: 24px;
  position: relative;

  & + & {
    border-top: 1px solid var(--bg-light);
  }
`

export const ActionTitle = styled.h4`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1.25rem;
  color: var(--text-dark-1);
`

export const ActionItemList = styled.div``

export const ActionItem = styled.div.attrs({
  className: 'flex items-center',
  draggable: true
})`
  border: 1px solid var(--bg-light-1);
  background-color: var(--bg-light-3);
  color: var(--text-dark-3);
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: grab;
  user-select: none;

  & + & {
    margin-top: 16px;
  }

  .react-icons {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`

export const WIPOverlay = styled.div.attrs({ className: 'flex flex-center' })`
  background-color: rgba(242, 242, 245, 0.75);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  user-select: none;
  color: var(--text-dark-3);
  flex-direction: column;

  span {
    font-weight: 600;
  }
  .react-icons {
    margin-bottom: 6px;
    width: 24px;
    height: 24px;
  }
`
